<template>
  <v-tooltip
    v-model="showTooltip"
    right
    transition="slide-x-transition"
    color="info"
  >
    <template #activator="{ attrs, on }">
      <span
        v-bind="attrs"
        v-on="on"
        class="clipboard-copy"
        @click="copy"
      >
        <slot></slot>
      </span>
    </template>

    Klik for at kopiere
  </v-tooltip>
</template>

<script>
export default {
  name: 'ClipboardCopy',
  data() {
    return {
      showTooltip: false
    }
  },
  methods: {
    copy(event) {
      this.showTooltip = false;
      const text = event.target.innerText.trim();

      if (!text) {
        this.$notifier.showError({ message: 'Noget gik galt! Kunne ikke kopiere.' })
        return;
      }

      navigator.clipboard.writeText(text);

      this.$notifier.showMessage({
        message: `"${text}" kopieret til udklipsholderen`,
        icon: 'mdi-information-outline',
        color: 'info'
      })
    }
  }
}
</script>

<style>
.clipboard-copy {
  cursor: pointer;
}
</style>