<template>
  <v-tooltip bottom>
    <template #activator="{ attrs, on }">
      <div v-if="price" v-bind="attrs" v-on="on">{{ price | formatNumberFixed2 }}</div>
      <span v-else class="text-caption">Ingen pris</span>
    </template>

    <div>
      Beregningstidspunkt: {{ calculatedOn | formatDateTime }}
    </div>
    <div>
      Metode: {{ calculationCriteria }}
    </div>
    <div v-if="provider">
      Kilde: {{ provider }}
    </div>
    <div v-if="fixedUntil">
      Låst indtil: {{ fixedUntil | formatDateTime }}
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: 'PriceCalculationDetails',
  props: {
    price: {
      type: Number | undefined,
      required: true
    },
    calculatedOn: {
      type: String,
      default: ''
    },
    calculationCriteria: {
      type: String,
      default: ''
    },
    fixedUntil: {
      type: String,
      default: ''
    },
    provider: {
      type: String,
      default: ''
    }
  }
}
</script>