<template>
  <div>
    <Loading :loading="loading" message="Indlæser prisforslag" />
    <v-card>
      <v-toolbar
        flat
        class="mb-2"
        extended
        extension-height="60"
      >
        <v-toolbar-title>
          Prisforslag
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <div
          class="d-flex align-center"
          style="gap: 1rem;"
        >
          <v-btn
            :loading="loading"
            icon
            @click="fetchData"
          >
            <v-icon>
              mdi-refresh
            </v-icon>
          </v-btn>
          <v-menu
            v-model="showConfirmApply"
            bottom
            left
            origin="right"
          >
            <template #activator="{ attrs, on }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="!selected.length"
                :loading="applying"
                color="primary"
              >
                Offentliggør priser
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                Er du sikker?
              </v-card-title>
              <v-card-text>
                De nye priser bliver synlige for de relevante kunder med det samme.
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  @click="showConfirmApply = false"
                >
                  Annuller
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="applyPending"
                >
                  Ja, offentliggør priser
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-menu
            v-model="showConfirmAbort"
            bottom
            left
            origin="right"
          >
            <template #activator="{ attrs, on }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="!selected.length"
                :loading="aborting"
                color="error"
              >
                Annuller prisforslag
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                Er du sikker?
              </v-card-title>
              <v-card-text>
                Valgte prisforslag bliver annulleret, men vil blive foreslået igen senere.
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  @click="showConfirmAbort = false"
                >
                  Annuller
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  @click="abortPending"
                >
                  Ja, annuller prisforslag
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>

        <template #extension>
          <div
            class="d-flex align-center justify-space-between"
            style="width: 100%; gap: 1rem;"
          >
            <v-text-field
              v-model="search"
              label="Søg"
              hide-details
              class="mr-4"
              clearable
            />
            <v-autocomplete
              v-model="selectedCustomerCategories"
              :items="customerCategories"
              label="Kundekategori"
              multiple
              hide-details
              small-chips
              clearable
            />
            <v-checkbox
              v-model="onlyFavorites"
              label="Kun favoritter"
              class="mr-4"
              hide-details
            />
            <v-range-slider
              v-model="range"
              :disabled="loading || items.length < 2"
              label="Kundepris ændring"
              class="mr-4 flex-grow-1"
              style="width: 30%;"
              hide-details
              thumb-label
              thumb-size="24"
              :min="min"
              :max="max"
            >
            </v-range-slider>
            <v-menu
              v-model="toggleColumnsMenu"
              left
              offset-y
              :close-on-content-click="false"
            >
              <template #activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon> mdi-table-cog </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="header in headers"
                  :key="header.value"
                >
                  <v-list-item-content>
                    {{ header.text }}
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-checkbox
                      v-model="header.show"
                    />
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-toolbar>

      <v-data-table
        v-model="selected"
        :headers="visibleHeaders"
        :items="items"
        :loading="loading"
        :items-per-page="30"
        :search.sync="search"
        sort-by="customerPriceDiffPct"
        sort-desc
        show-select
        no-data-text="Ingen data"
        no-results-text="Ingen resultater"
        loading-text="Indlæser prisforslag"
        :footer-props="{ 'items-per-page-options': [15, 30, 50, 75, -1] }"
        @dblclick:row="goToPart"
      >
        <template #[`item.ean`]="{ item }">
          <clipboard-copy>{{ item.ean }}</clipboard-copy>
        </template>
        <template #[`item.partNumber`]="{ item }">
          <clipboard-copy>{{ item.partNumber }}</clipboard-copy>
        </template>
        <template #[`item.tpaPartNumber`]="{ item }">
          <clipboard-copy>{{ item.tpaPartNumber }}</clipboard-copy>
        </template>
        <template #[`item.isFavorite`]="{ item }">
          <v-icon
            v-if="item.isFavorite"
            color="yellow"
          >
            mdi-star
          </v-icon>
        </template>
        <template #[`item.currentCostPrice`]="{ item }">
          {{ item.currentCostPrice | formatNumberFixed2 }}
        </template>
        <template #[`item.currentCustomerPrice`]="{ item }">
          {{ item.currentCustomerPrice | formatNumberFixed2 }}
        </template>
        <template #[`item.newCustomerPrice`]="{ item }">
          <PriceCalculationDetails
            :price="item.newCustomerPrice"
            :calculationCriteria="item.priceCalculationCriteria"
            :calculatedOn="item.priceCalculatedOn"
            :provider="item.priceCalculationProvider"
          />
        </template>
        <template #[`item.customerPriceDiff`]="{ item }">
          {{ item.customerPriceDiff | formatNumberFixed2 }}
        </template>
        <template #[`item.customerPriceDiffPct`]="{ item }">
          <span :class="{ 'red--text font-weight-bold': Math.sign(item.customerPriceDiffPct) === -1 }">
            {{ item.customerPriceDiffPct }}
          </span>
        </template>
        <template #[`item.suggestedRetailPrice`]="{ item }">
          {{ item.suggestedRetailPrice | formatNumberFixed2 }}
        </template>
        <template #[`item.suggestedRetailPriceDiffPct`]="{ item }">
          <span :class="{ 'red--text font-weight-bold': Math.sign(item.suggestedRetailPriceDiffPct) === -1 }">
            {{ item.suggestedRetailPriceDiffPct }}
          </span>
        </template>
      </v-data-table>

      <v-card-text>
        <div>
          Procentsatser er rundet op
        </div>
        <div class="text-caption">
          Tip: Dobbeltklik på en række for at gå til redigering af en vare
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import PriceCalculationDetails from '@/components/PriceCalculationDetails.vue'
import ClipboardCopy from '@/components/ClipboardCopy.vue'

export default {
  name: 'PricePendingAdmin',
  components: {
    Loading,
    PriceCalculationDetails,
    ClipboardCopy
  },
  data() {
    return {
      loading: false,
      applying: false,
      aborting: false,
      headers: [
        { text: 'Titel', value: 'title', width: '20%', show: true },
        { text: 'EAN', value: 'ean', show: true },
        { text: 'Varenummer', value: 'partNumber', show: true },
        { text: 'TPA Varenummer', value: 'tpaPartNumber', show: true },
        { text: 'Producent', value: 'manufacturer', show: true },
        { text: 'Favorit', value: 'isFavorite', align: 'center', show: true },
        // { text: 'Kunde', value: 'customerName' },
        {
          text: 'Kunde kategori',
          value: 'customerCategory',
          filter: (value) => this.selectedCustomerCategories.length ?  this.selectedCustomerCategories.includes(value) : true,
          show: true
        },
        { text: 'Kostpris aktuel', value: 'currentCostPrice', align: 'end', show: true },
        { text: 'Kundepris aktuel', value: 'currentCustomerPrice', align: 'end', show: true },
        { text: 'Kundepris ny', value: 'newCustomerPrice', align: 'end', show: true },
        { text: 'Kundepris ændring', value: 'customerPriceDiff', align: 'end', show: true },
        {
          text: 'Kundepris ændring (%)',
          value: 'customerPriceDiffPct',
          align: 'end',
          filter: (value) => value >= this.range[0] && value <= this.range[1],
          show: true
        },
        { text: 'Vejl. salgspris', value: 'suggestedRetailPrice', align: 'end', show: true },
        { text: 'Kundepris vs. Vejl. (%)', value: 'suggestedRetailPriceDiff', align: 'end', show: true }
      ],
      onlyFavorites: false,
      pendingItems: [],
      min: -50,
      max: 50,
      range: [ -50, 50 ],
      search: this.$route.query.search ?? '',
      selected: [],
      selectedCustomerCategories: [],
      showConfirmApply: false,
      showConfirmAbort: false,
      toggleColumnsMenu: false
    }
  },
  computed: {
    items() {
      let elements = this.pendingItems.map(x => {
        return {
          ...x,
          customerPriceDiff: x.currentCustomerPrice ? x.newCustomerPrice - x.currentCustomerPrice : null,
          customerPriceDiffPct: x.currentCustomerPrice ? (((x.newCustomerPrice - x.currentCustomerPrice) / x.currentCustomerPrice) * 100).toFixed(0) : null,
          suggestedRetailPriceDiff: x.suggestedRetailPrice ? ((x.newCustomerPrice / x.suggestedRetailPrice) * 100).toFixed(0) : null
        };
      });

      if (this.onlyFavorites && this.pendingItems.length) {
        elements = elements.filter(item => item.isFavorite);
      }

      return elements;
    },
    customerCategories() {
      const uniqueCategories = new Set(this.pendingItems.map(x => x.customerCategory));

      return [ ...uniqueCategories ];
    },
    visibleHeaders() {
      return this.headers.filter(header => header.show === true);
    }
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;

        const { data } = await this.$AuthService.api.get(`adminpart/PricePending`);

        this.pendingItems = data?.pendingItems;
      } catch (error) {
        this.$notifier.showError({});
      } finally {
        this.loading = false;
      }
    },
    goToPart(event, { item }) {
      const route = this.$router.resolve({ path: `/parts/${item.part_Id}/edit` });
      window.open(route.href, '_blank');
    },
    async applyPending() {
      const data = {
        partCustomerDetailIds: this.selected.map(item => item.id)
      };

      try {
        this.applying = true;

        const response = await this.$AuthService.api.post('adminpart/pricepending', data);

        if (!response.data.success) throw response.data;

        this.pendingItems = response.data?.model?.pendingItems;
        this.selected = [];
        this.$notifier.showSuccess({ message: 'Priser er offentliggjort' });
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'Noget gik galt!' });
      } finally {
        this.applying = false;
      }
    },
    async abortPending() {
      const data = {
        partCustomerDetailIds: this.selected.map(item => item.id)
      };

      try {
        this.aborting = true;

        const response = await this.$AuthService.api.delete('adminpart/pricepending', {data: data});

        if (!response.data.success) throw response.data;

        this.pendingItems = response.data?.model?.pendingItems;
        this.selected = [];
        this.$notifier.showSuccess({ message: 'Prisændringerne er annulleret' });
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'Noget gik galt!' });
      } finally {
        this.aborting = false;
      }
    }
  },
  async mounted() {
    this.fetchData();
  },
  watch: {
    items(value) {
      if (value.length < 2) return;

      const diffs = value.map(x => x.customerPriceDiffPct);

      this.min = Math.min(...diffs);
      this.max = Math.max(...diffs);
      this.range = [ this.min, this.max ];
    }
  }
}
</script>